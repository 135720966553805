// NotificationBar.js
import React from 'react';
import './not.css'; // Create a CSS file for styling if needed

const NotificationBar = () => {
    return (
        <div className="notification-bar">
            <p>Sayt test rejimida ishlamoqda.</p>
        </div>
    );
}

export default NotificationBar;
